<template>
  <div
    class="flex flex-col items-center justify-center flex-1 pb-2 my-4 bg-white border-t-2 shadow"
    :class="{
      'border-blue-500': !isGreen && !isRed && !isYellow,
      'border-green-500': isGreen,
      'border-red-500': isRed,
      'border-yellow-500': isYellow,
    }"
  >
    <div>
      <div
        class="px-4 pt-2 text-xs font-bold tracking-wide text-center text-gray-700 uppercase truncate"
        :class="{
          'text-green-800': isGreen,
          'text-red-800': isRed,
          'text-yellow-800': isYellow,
        }"
        v-if="data.title"
      >
        {{ data.title }}
      </div>
      <div
        class="flex items-center justify-center px-4 font-bold"
        :class="{
          'text-blue-800': !isGreen && !isRed && !isYellow,
          'text-green-700': isGreen,
          'text-red-700': isRed,
          'text-yellow-700': isYellow,
        }"
      >
        <div class="mr-2 text-xl" v-if="data.value">{{ data.value }}</div>
        <div class="text-sm" v-if="data.percentage">
          {{ data.percentage | percentage("precise") }}
        </div>
        <div
          v-if="data.dealsWithEstimatedValue"
          class="ml-2 text-xs font-bold"
          :class="{
            'text-blue-600': !isGreen && !isRed && !isYellow,
            'text-green-600': isGreen,
            'text-red-600': isRed,
            'text-yellow-600': isYellow,
          }"
        >
          {{ data.dealsWithEstimatedValue }}
        </div>
        <div
          v-if="data.dealsWithEstimatedValuePercentage"
          class="ml-2 text-xs font-bold"
          :class="{
            'text-blue-600': !isGreen && !isRed && !isYellow,
            'text-green-600': isGreen,
            'text-red-600': isRed,
            'text-yellow-600': isYellow,
          }"
        >
          {{ data.dealsWithEstimatedValuePercentage | percentage("precise") }}
        </div>
      </div>
      <div class="flex items-center justify-center px-4">
        <div
          class="text-sm font-bold"
          :class="{
            'text-blue-600': !isGreen && !isRed && !isYellow,
            'text-green-600': isGreen,
            'text-red-600': isRed,
            'text-yellow-600': isYellow,
          }"
          v-if="data.price"
        >
          &euro; {{ data.price | rounded(0) | localized }}
        </div>
        <div
          v-if="data.pricePercentage"
          class="ml-2 text-xs font-bold"
          :class="{
            'text-blue-600': !isGreen && !isRed && !isYellow,
            'text-green-600': isGreen,
            'text-red-600': isRed,
            'text-yellow-600': isYellow,
          }"
        >
          {{ data.pricePercentage | percentage("precise") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberBox",
  props: {
    data: {
      type: Object,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
    isYellow: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    percentage(value, precise) {
      if (!value) return "0%";
      if (value.typeOf == String) value = value.parseFloat();
      if (precise == "precise") {
        return value.toFixed(1) + "%";
      } else {
        return value.toFixed(0) + "%";
      }
    },
    rounded(value, decimals) {
      if (!value) return "0";
      if (value.typeOf == String) value = value.parseFloat();
      return Number(value.toFixed(decimals));
    },
    localized(value) {
      if (!value) return "";
      if (value.typeOf == String) return value;
      return value.toLocaleString();
    },
  },
};
</script>

import Chart from "chart.js";
import { Bar } from "vue-chartjs";

Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.defaultFontFamily = "'Gilroy', sans-serif";
Chart.defaults.global.defaultFontColor = "#1c3f61";
Chart.defaults.global.defaultFontStyle = 600;

export default {
  extends: Bar,
  props: ["data", "options"],
  mounted() {
    this.renderChart(this.data, this.options);
  },
};

<template>
  <div class="py-2 text-white bg-gray-800 shadow">
    <div class="flex items-center justify-between px-4 mx-auto max-w-7xl">
      <h1 class="text-2xl font-bold">
        <i class="mr-1 far fa-chart-line"></i>
        Flux
      </h1>
      <div
        class="flex items-center space-x-2 text-gray-400 border-b-2 border-transparent"
      >
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          class="px-3 py-2 text-sm font-semibold transition border-b-2 focus:outline-none hover:text-white"
          :class="{
            'text-white border-white': activeTab === index,
            'border-transparent': activeTab !== index,
          }"
          @click="activeTab = index"
        >
          {{ tab }}
        </button>
      </div>
      <div class="flex items-center">
        <p class="g-signin2" data-onsuccess="queryReports"></p>
        <button
          class="px-3 py-2 ml-2 text-sm font-semibold text-white transition border-b-2 rounded bg-brand-700 border-brand-900 focus:outline-none focus:ring-2 ring-accent-500"
          @click="initiateTeamleaderAuth"
        >
          Teamleader Re-Auth
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "DashboardHeader",
  data() {
    return {
      tabs: ["Aanvraagconversie", "Overzichten"],
    };
  },
  computed: {
    activeTab: sync("activeTab"),
    TeamleaderAuthenticated() {
      return this.$store.getters.TeamleaderAuthenticated;
    },
    TeamleaderAPICallsInLastMinute() {
      return this.$store.getters.TeamleaderAPICallsInLastMinute;
    },
  },
  methods: {
    initiateTeamleaderAuth() {
      window.location.href = `https://app.teamleader.eu/oauth2/authorize?client_id=9ae8a7c2b9bfb3083c350acaf8e743f9&response_type=token&redirect_uri=${
        window.location.href.split(/[?#]/)[0]
      }`;
    },
  },
};
</script>

<template>
  <div id="app" class="min-h-screen pb-2 bg-gray-400 text-brand-700">
    <div
      v-if="
        TeamleaderUsersLoaded &&
          TeamleaderDealSourcesLoaded &&
          TeamleaderDealsLoaded &&
          GoogleAnalyticsLoaded &&
          GA4DataLoaded
      "
    >
      <dashboard-header></dashboard-header>
      <router-view />
    </div>
    <div
      class="flex items-center justify-center w-full h-screen"
      v-show="TeamleaderAuthenticated === false"
    >
      <div class="p-4 bg-white border-t-4 rounded border-brand-700">
        <h1 class="text-xl font-bold">Welkom bij Flux</h1>
        <div class="mt-1 font-semibold text-gray-600">
          Zorg eerst dat je ingelogd bent bij Google en Teamleader
        </div>
        <div class="flex items-center mt-4">
          <p class="g-signin2" data-onsuccess="queryReports"></p>
          <button
            class="px-3 py-2 ml-2 text-sm font-semibold text-white transition border-b-2 rounded bg-brand-700 border-brand-900 focus:outline-none focus:ring-2 ring-accent-500"
            @click="initiateTeamleaderAuth"
          >
            Teamleader Login
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-center w-full h-screen"
      v-if="
        (TeamleaderUsersLoaded === false ||
          TeamleaderDealSourcesLoaded === false ||
          TeamleaderDealsLoaded === false ||
          GoogleAnalyticsLoaded === false ||
          GA4DataLoaded === false) &&
          TeamleaderAuthenticated
      "
    >
      <div class="font-semibold">
        <div>
          <i class="fad fa-spinner fa-spin" v-if="!TeamleaderUsersLoaded"></i>
          <i class="fad fa-check" v-else></i>
          Fetching Teamleader gebruikers...
        </div>
        <div>
          <i
            class="fad fa-spinner fa-spin"
            v-if="!TeamleaderDealSourcesLoaded"
          ></i>
          <i class="fad fa-check" v-else></i>
          Fetching Teamleader deal bronnen...
        </div>
        <div>
          <i class="fad fa-spinner fa-spin" v-if="!TeamleaderDealsLoaded"></i>
          <i class="fad fa-check" v-else></i>
          Fetching Teamleader deals...
        </div>
        <div>
          <i class="fad fa-spinner fa-spin" v-if="!GoogleAnalyticsLoaded"></i>
          <i class="fad fa-check" v-else></i>
          Fetching Google Analytics...
        </div>
        <div>
          <i class="fad fa-spinner fa-spin" v-if="!GA4DataLoaded"></i>
          <i class="fad fa-check" v-else></i>
          Fetching GA4...
        </div>
        <div
          class="h-3 mt-4 overflow-hidden transition bg-gray-300 rounded w-96"
        >
          <div
            class="w-0 h-full bg-brand-700"
            :class="{
              'w-1/5': TeamleaderUsersLoaded,
              'w-2/5': TeamleaderDealSourcesLoaded,
              'w-3/5': GoogleAnalyticsLoaded,
              'w-4/5': GA4DataLoaded,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

import DashboardHeader from "@/components/DashboardHeader.vue";

export default {
  components: {
    DashboardHeader,
  },
  data() {
    return {
      TeamleaderAuthenticated: false,
    };
  },
  computed: {
    TeamleaderUsersLoaded: get("TeamleaderUsersLoaded"),
    TeamleaderDealSourcesLoaded: get("TeamleaderDealSourcesLoaded"),
    TeamleaderDealsLoaded: get("TeamleaderDealsLoaded"),
    GoogleAnalyticsLoaded: get("GoogleAnalyticsLoaded"),
    GA4DataLoaded: get("GA4DataLoaded"),
  },
  methods: {
    initiateTeamleaderAuth() {
      window.location.href = `https://app.teamleader.eu/oauth2/authorize?client_id=9ae8a7c2b9bfb3083c350acaf8e743f9&response_type=token&redirect_uri=${
        window.location.href.split(/[?#]/)[0]
      }`;
    },
    initializeDashboard() {
      this.$store
        .dispatch("checkIfTeamleaderAuthenticated")
        .then(() => {
          this.$store.dispatch("fetchTeamleaderUsers");
        })
        .then(() => {
          this.$store.dispatch("fetchTeamleaderDealSources");
        })
        .then(() => {
          this.$store.dispatch("fetchTeamleaderDealPhases");
        })
        .then(() => {
          this.$store.commit("clear_TeamleaderDeals");
          this.$store.dispatch("fetchTeamleaderDeals");
        });
    },
  },
  mounted() {
    if (window.location.href.indexOf("access_token") > -1) {
      this.TeamleaderAuthenticated = true;
    }
    this.initializeDashboard();
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main";
</style>

<template>
  <div class="flex flex-col mb-4 overflow-hidden bg-gray-200 rounded shadow">
    <div
      class="flex items-center justify-between px-3 py-2 text-white bg-gray-800 shadow"
    >
      <h2 class="flex items-center ml-3 text-lg font-bold">
        <slot name="title" />
      </h2>
      <button class="px-3 py-1 button-ghost" @click="active = !active">
        <i class="far fa-chevron-down" :class="{ 'fa-chevron-up': active }"></i>
      </button>
    </div>
    <div class="flex flex-wrap flex-1 px-4">
      <slot name="boxes" />
    </div>
    <div class="w-full overflow-x-auto">
      <table class="flux-table" v-show="active">
        <slot name="leadsTable" />
      </table>
    </div>
    <div class="w-full overflow-x-auto">
      <table class="border-t-2 border-gray-200 flux-table" v-show="active">
        <slot name="personsTable" />
      </table>
    </div>
    <div class="w-full overflow-x-auto">
      <table class="border-t-2 border-gray-200 flux-table" v-show="active">
        <slot name="sourcesTable" />
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberRow",
  data() {
    return {
      active: false,
    };
  },
};
</script>

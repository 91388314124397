var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-screen pb-2 bg-gray-400 text-brand-700",attrs:{"id":"app"}},[(
      _vm.TeamleaderUsersLoaded &&
        _vm.TeamleaderDealSourcesLoaded &&
        _vm.TeamleaderDealsLoaded &&
        _vm.GoogleAnalyticsLoaded &&
        _vm.GA4DataLoaded
    )?_c('div',[_c('dashboard-header'),_c('router-view')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.TeamleaderAuthenticated === false),expression:"TeamleaderAuthenticated === false"}],staticClass:"flex items-center justify-center w-full h-screen"},[_c('div',{staticClass:"p-4 bg-white border-t-4 rounded border-brand-700"},[_c('h1',{staticClass:"text-xl font-bold"},[_vm._v("Welkom bij Flux")]),_c('div',{staticClass:"mt-1 font-semibold text-gray-600"},[_vm._v(" Zorg eerst dat je ingelogd bent bij Google en Teamleader ")]),_c('div',{staticClass:"flex items-center mt-4"},[_c('p',{staticClass:"g-signin2",attrs:{"data-onsuccess":"queryReports"}}),_c('button',{staticClass:"px-3 py-2 ml-2 text-sm font-semibold text-white transition border-b-2 rounded bg-brand-700 border-brand-900 focus:outline-none focus:ring-2 ring-accent-500",on:{"click":_vm.initiateTeamleaderAuth}},[_vm._v(" Teamleader Login ")])])])]),(
      (_vm.TeamleaderUsersLoaded === false ||
        _vm.TeamleaderDealSourcesLoaded === false ||
        _vm.TeamleaderDealsLoaded === false ||
        _vm.GoogleAnalyticsLoaded === false ||
        _vm.GA4DataLoaded === false) &&
        _vm.TeamleaderAuthenticated
    )?_c('div',{staticClass:"flex items-center justify-center w-full h-screen"},[_c('div',{staticClass:"font-semibold"},[_c('div',[(!_vm.TeamleaderUsersLoaded)?_c('i',{staticClass:"fad fa-spinner fa-spin"}):_c('i',{staticClass:"fad fa-check"}),_vm._v(" Fetching Teamleader gebruikers... ")]),_c('div',[(!_vm.TeamleaderDealSourcesLoaded)?_c('i',{staticClass:"fad fa-spinner fa-spin"}):_c('i',{staticClass:"fad fa-check"}),_vm._v(" Fetching Teamleader deal bronnen... ")]),_c('div',[(!_vm.TeamleaderDealsLoaded)?_c('i',{staticClass:"fad fa-spinner fa-spin"}):_c('i',{staticClass:"fad fa-check"}),_vm._v(" Fetching Teamleader deals... ")]),_c('div',[(!_vm.GoogleAnalyticsLoaded)?_c('i',{staticClass:"fad fa-spinner fa-spin"}):_c('i',{staticClass:"fad fa-check"}),_vm._v(" Fetching Google Analytics... ")]),_c('div',[(!_vm.GA4DataLoaded)?_c('i',{staticClass:"fad fa-spinner fa-spin"}):_c('i',{staticClass:"fad fa-check"}),_vm._v(" Fetching GA4... ")]),_c('div',{staticClass:"h-3 mt-4 overflow-hidden transition bg-gray-300 rounded w-96"},[_c('div',{staticClass:"w-0 h-full bg-brand-700",class:{
            'w-1/5': _vm.TeamleaderUsersLoaded,
            'w-2/5': _vm.TeamleaderDealSourcesLoaded,
            'w-3/5': _vm.GoogleAnalyticsLoaded,
            'w-4/5': _vm.GA4DataLoaded,
          }})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="home">
    <div
      class="p-4 pb-0 mx-auto max-w-7xl"
      v-if="
        TeamleaderUsersLoaded &&
          TeamleaderDealSourcesLoaded &&
          TeamleaderDealsLoaded &&
          GoogleAnalyticsLoaded &&
          GA4DataLoaded &&
          activeTab === 0
      "
    >
      <div
        class="flex items-center px-3 py-2 mb-4 text-sm text-gray-600 bg-white rounded shadow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="ml-2 font-bold"
          >De Google Analytics gegevens van mei, juni en juli 2021 komen uit
          GA4, waar de rest uit UA komt</span
        >
      </div>
      <number-row>
        <template v-slot:title>
          <span>Totaal</span>
          <span class="ml-4 text-sm text-gray-200 font-roboto"
            >{{ TeamleaderEarliestDealDate }} -
            {{ TeamleaderLatestDealDate }}</span
          >
        </template>
        <template v-slot:boxes>
          <number-box
            class="rounded"
            :data="{
              title: 'Sitebezoekers',
              value: GoogleAnalyticsAndGa4DataMergedTotal,
            }"
          />
          <number-box
            class="ml-6 rounded"
            :data="{
              title: 'Aanvragen',
              value: TeamleaderDeals.length,
              percentage:
                (TeamleaderDeals.length /
                  GoogleAnalyticsAndGa4DataMergedTotal) *
                100,
              price: TeamleaderDealsTotalValues.totalDealsValue,
              dealsWithEstimatedValue: TeamleaderDealsWithEstimatedValue.length,
            }"
          />
          <number-box
            class="ml-6 rounded-tl rounded-bl"
            isYellow
            :data="{
              title: 'Processing',
              value:
                TeamleaderDeals.length -
                TeamleaderWonDeals.length -
                TeamleaderLostDeals.length,
              percentage:
                ((TeamleaderDeals.length -
                  TeamleaderWonDeals.length -
                  TeamleaderLostDeals.length) /
                  TeamleaderDeals.length) *
                100,
              price: TeamleaderDealsTotalValues.totalProcessingDealsValue,
              pricePercentage:
                (TeamleaderDealsTotalValues.totalProcessingDealsValue /
                  TeamleaderDealsTotalValues.totalDealsValue) *
                100,
            }"
          />
          <number-box
            isGreen
            :data="{
              title: 'Aanvaard',
              value: TeamleaderWonDeals.length,
              percentage:
                (TeamleaderWonDeals.length / TeamleaderDeals.length) * 100,
              price: TeamleaderDealsTotalValues.totalWonDealsValue,
              pricePercentage:
                (TeamleaderDealsTotalValues.totalWonDealsValue /
                  TeamleaderDealsTotalValues.totalDealsValue) *
                100,
              dealsWithEstimatedValuePercentage:
                (TeamleaderWonDeals.length /
                  TeamleaderDealsWithEstimatedValue.length) *
                100,
            }"
          />
          <number-box
            class="rounded-tr rounded-br"
            isRed
            :data="{
              title: 'Geweigerd',
              value: TeamleaderLostDeals.length,
              percentage:
                (TeamleaderLostDeals.length / TeamleaderDeals.length) * 100,
              price: TeamleaderDealsTotalValues.totalLostDealsValue,
              pricePercentage:
                (TeamleaderDealsTotalValues.totalLostDealsValue /
                  TeamleaderDealsTotalValues.totalDealsValue) *
                100,
            }"
          />
        </template>
        <template v-slot:leadsTable>
          <tr class="text-sm">
            <th></th>
            <th class="pl-4 font-bold text-blue-800">
              Aanvragen
              <i class="ml-1 text-xs fad fa-chevron-down"></i>
            </th>
            <th class="pl-4 font-bold text-yellow-800">Processing</th>
            <th class="pl-4 font-bold text-green-800">Aanvaard</th>
            <th class="pl-4 font-bold text-red-800">Geweigerd</th>
          </tr>
          <tr
            v-for="(lead, index) in TeamleaderDealsPerLeadType"
            :key="index"
            class="text-sm"
          >
            <td class="font-medium text-gray-700">{{ lead.name }}</td>
            <td class="pl-4 font-bold">
              <span class="text-blue-800">{{ lead.deals.length }}</span>
              <span class="ml-3 text-blue-800">{{
                ((lead.deals.length / GoogleAnalyticsAndGa4DataMergedTotal) *
                  100)
                  | percentage("precise")
              }}</span>
              <span class="ml-3 text-blue-600"
                >&euro;
                {{ lead.totalDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-yellow-800">{{
                lead.processingDeals.length
              }}</span>
              <span class="ml-3 text-yellow-800">{{
                ((lead.processingDeals.length / lead.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-yellow-600"
                >&euro;
                {{
                  lead.totalProcessingDealsValue | rounded(0) | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-green-800">{{ lead.wonDeals.length }}</span>
              <span class="ml-3 text-green-800">{{
                ((lead.wonDeals.length / lead.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-green-600"
                >&euro;
                {{ lead.totalWonDealsValue | rounded(0) | localized }}</span
              >
              <span class="ml-3 text-green-400"
                >&euro;
                {{
                  (lead.totalWonDealsValue / lead.wonDeals.length)
                    | rounded(0)
                    | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-red-800">{{ lead.lostDeals.length }}</span>
              <span class="ml-3 text-red-800">{{
                ((lead.lostDeals.length / lead.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-red-600"
                >&euro;
                {{ lead.totalLostDealsValue | rounded(0) | localized }}</span
              >
            </td>
          </tr>
        </template>
        <template v-slot:personsTable>
          <tr class="text-sm">
            <th></th>
            <th class="pl-4 font-bold text-blue-800">Aanvragen</th>
            <th class="pl-4 font-bold text-yellow-800">Processing</th>
            <th class="pl-4 font-bold text-green-800">
              Aanvaard
              <i class="ml-1 text-xs fad fa-chevron-down"></i>
            </th>
            <th class="pl-4 font-bold text-red-800">Geweigerd</th>
          </tr>
          <tr
            v-for="(user, index) in TeamleaderDealsPerUser"
            :key="index"
            class="text-sm"
            v-show="user.deals.length != 0"
          >
            <td class="font-medium text-gray-700">{{ user.name }}</td>
            <td class="pl-4 font-bold">
              <span class="text-blue-800">{{ user.deals.length }}</span>
              <span class="ml-3 text-blue-600"
                >&euro;
                {{ user.totalDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-yellow-800">{{
                user.processingDeals.length
              }}</span>
              <span class="ml-3 text-yellow-800">{{
                ((user.processingDeals.length / user.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-yellow-600"
                >&euro;
                {{
                  user.totalProcessingDealsValue | rounded(0) | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-green-800">{{ user.wonDeals.length }}</span>
              <span class="ml-3 text-green-800">{{
                ((user.wonDeals.length / user.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-green-600"
                >&euro;
                {{ user.totalWonDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-red-800">{{ user.lostDeals.length }}</span>
              <span class="ml-3 text-red-800">{{
                ((user.lostDeals.length / user.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-red-600"
                >&euro;
                {{ user.totalLostDealsValue | rounded(0) | localized }}</span
              >
            </td>
          </tr>
        </template>
        <template v-slot:sourcesTable>
          <tr class="text-sm">
            <th></th>
            <th class="pl-4 font-bold text-blue-800">
              Aanvragen
              <i class="ml-1 text-xs fad fa-chevron-down"></i>
            </th>
            <th class="pl-4 font-bold text-yellow-800">Processing</th>
            <th class="pl-4 font-bold text-green-800">Aanvaard</th>
            <th class="pl-4 font-bold text-red-800">Geweigerd</th>
          </tr>
          <tr
            v-for="(source, index) in TeamleaderDealsPerSource"
            :key="index"
            class="text-sm"
            v-show="source.deals.length != 0"
          >
            <td class="font-medium text-gray-700">{{ source.name }}</td>
            <td class="pl-4 font-bold">
              <span class="text-blue-800">{{ source.deals.length }}</span>
              <span class="ml-3 text-blue-600"
                >&euro;
                {{ source.totalDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-yellow-800">{{
                source.processingDeals.length
              }}</span>
              <span class="ml-3 text-yellow-800">{{
                ((source.processingDeals.length / source.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-yellow-600"
                >&euro;
                {{
                  source.totalProcessingDealsValue | rounded(0) | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-green-800">{{ source.wonDeals.length }}</span>
              <span class="ml-3 text-green-800">{{
                ((source.wonDeals.length / source.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-green-600"
                >&euro;
                {{ source.totalWonDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-red-800">{{ source.lostDeals.length }}</span>
              <span class="ml-3 text-red-800">{{
                ((source.lostDeals.length / source.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-red-600"
                >&euro;
                {{ source.totalLostDealsValue | rounded(0) | localized }}</span
              >
            </td>
          </tr>
        </template>
      </number-row>
      <div class="my-16"></div>
      <number-row
        v-for="(month, monthIndex) in TeamleaderDealsSplitByMonth"
        :key="monthIndex"
        v-show="TeamleaderDealsSplitByMonth[monthIndex].deals.length > 0"
      >
        <template v-slot:title>{{ month.month }} {{ month.year }}</template>
        <template v-slot:boxes>
          <number-box
            class="rounded"
            :data="{
              title: 'Sitebezoekers',
              value: GoogleAnalyticsAndGa4DataMerged[monthIndex],
            }"
          />
          <number-box
            class="ml-6 rounded"
            :data="{
              title: 'Aanvragen',
              value: TeamleaderDealsSplitByMonth[monthIndex].deals.length,
              percentage:
                (TeamleaderDealsSplitByMonth[monthIndex].deals.length /
                  GoogleAnalyticsAndGa4DataMerged[monthIndex]) *
                100,
              price:
                TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalDealsValue,
              dealsWithEstimatedValue:
                TeamleaderDealsSplitByMonthWithEstimatedValue[monthIndex]
                  .length,
            }"
          />
          <number-box
            class="ml-6 rounded-tl rounded-bl"
            isYellow
            :data="{
              title: 'Processing',
              value:
                TeamleaderDealsSplitByMonthInProcess[monthIndex].deals.length,
              percentage:
                (TeamleaderDealsSplitByMonthInProcess[monthIndex].deals.length /
                  TeamleaderDealsSplitByMonth[monthIndex].deals.length) *
                100,
              price:
                TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalProcessingDealsValue,
              pricePercentage:
                (TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalProcessingDealsValue /
                  TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                    .totalDealsValue) *
                100,
            }"
          />
          <number-box
            isGreen
            :data="{
              title: 'Aanvaard',
              value: TeamleaderDealsSplitByMonthWon[monthIndex].deals.length,
              percentage:
                (TeamleaderDealsSplitByMonthWon[monthIndex].deals.length /
                  TeamleaderDealsSplitByMonth[monthIndex].deals.length) *
                100,
              price:
                TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalWonDealsValue,
              pricePercentage:
                (TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalWonDealsValue /
                  TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                    .totalDealsValue) *
                100,
              dealsWithEstimatedValuePercentage:
                (TeamleaderDealsSplitByMonthWon[monthIndex].deals.length /
                  TeamleaderDealsSplitByMonthWithEstimatedValue[monthIndex]
                    .length) *
                100,
            }"
          />
          <number-box
            class="rounded-tr rounded-br"
            isRed
            :data="{
              title: 'Geweigerd',
              value: TeamleaderDealsSplitByMonthLost[monthIndex].deals.length,
              percentage:
                (TeamleaderDealsSplitByMonthLost[monthIndex].deals.length /
                  TeamleaderDealsSplitByMonth[monthIndex].deals.length) *
                100,
              price:
                TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalLostDealsValue,
              pricePercentage:
                (TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                  .totalLostDealsValue /
                  TeamleaderDealsSplitByMonthTotalValues[monthIndex].totalValues
                    .totalDealsValue) *
                100,
            }"
          />
        </template>
        <template v-slot:leadsTable>
          <tr class="text-sm">
            <th></th>
            <th class="pl-4 font-bold text-blue-800">
              Aanvragen
              <i class="ml-1 text-xs fad fa-chevron-down"></i>
            </th>
            <th class="pl-4 font-bold text-yellow-800">Processing</th>
            <th class="pl-4 font-bold text-green-800">Aanvaard</th>
            <th class="pl-4 font-bold text-red-800">Geweigerd</th>
          </tr>
          <tr
            v-for="(lead, index) in TeamleaderDealsSplitByMonthPerLeadType[
              monthIndex
            ].deals"
            :key="index"
            class="text-sm"
          >
            <td class="font-medium text-gray-700">{{ lead.name }}</td>
            <td class="pl-4 font-bold">
              <span class="text-blue-800">{{ lead.deals.length }}</span>
              <span class="ml-3 text-blue-800">{{
                ((lead.deals.length /
                  GoogleAnalyticsAndGa4DataMerged[monthIndex]) *
                  100)
                  | percentage("precise")
              }}</span>
              <span class="ml-3 text-blue-600"
                >&euro;
                {{ lead.totalDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-yellow-800">{{
                lead.processingDeals.length
              }}</span>
              <span class="ml-3 text-yellow-800">{{
                ((lead.processingDeals.length / lead.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-yellow-600"
                >&euro;
                {{
                  lead.totalProcessingDealsValue | rounded(0) | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-green-800">{{ lead.wonDeals.length }}</span>
              <span class="ml-3 text-green-800">{{
                ((lead.wonDeals.length / lead.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-green-600"
                >&euro;
                {{ lead.totalWonDealsValue | rounded(0) | localized }}</span
              >
              <span class="ml-3 text-green-400"
                >&euro;
                {{
                  (lead.totalWonDealsValue / lead.wonDeals.length)
                    | rounded(0)
                    | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-red-800">{{ lead.lostDeals.length }}</span>
              <span class="ml-3 text-red-800">{{
                ((lead.lostDeals.length / lead.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-red-600"
                >&euro;
                {{ lead.totalLostDealsValue | rounded(0) | localized }}</span
              >
            </td>
          </tr>
        </template>
        <template v-slot:personsTable>
          <tr class="text-sm">
            <th></th>
            <th class="pl-4 font-bold text-blue-800">Aanvragen</th>
            <th class="pl-4 font-bold text-yellow-800">Processing</th>
            <th class="pl-4 font-bold text-green-800">
              Aanvaard
              <i class="ml-1 text-xs fad fa-chevron-down"></i>
            </th>
            <th class="pl-4 font-bold text-red-800">Geweigerd</th>
          </tr>
          <tr
            v-for="(user, index) in TeamleaderDealsSplitByMonthPerUser[
              monthIndex
            ].deals"
            :key="index"
            class="text-sm"
            v-show="user.deals.length != 0"
          >
            <td class="font-medium text-gray-700">{{ user.name }}</td>
            <td class="pl-4 font-bold">
              <span class="text-blue-800">{{ user.deals.length }}</span>
              <span class="ml-3 text-blue-600"
                >&euro;
                {{ user.totalDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-yellow-800">{{
                user.processingDeals.length
              }}</span>
              <span class="ml-3 text-yellow-800">{{
                ((user.processingDeals.length / user.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-yellow-600"
                >&euro;
                {{
                  user.totalProcessingDealsValue | rounded(0) | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-green-800">{{ user.wonDeals.length }}</span>
              <span class="ml-3 text-green-800">{{
                ((user.wonDeals.length / user.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-green-600"
                >&euro;
                {{ user.totalWonDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-red-800">{{ user.lostDeals.length }}</span>
              <span class="ml-3 text-red-800">{{
                ((user.lostDeals.length / user.deals.length) * 100) | percentage
              }}</span>
              <span class="ml-3 text-red-600"
                >&euro;
                {{ user.totalLostDealsValue | rounded(0) | localized }}</span
              >
            </td>
          </tr>
        </template>
        <template v-slot:sourcesTable>
          <tr class="text-sm">
            <th></th>
            <th class="pl-4 font-bold text-blue-800">
              Aanvragen
              <i class="ml-1 text-xs fad fa-chevron-down"></i>
            </th>
            <th class="pl-4 font-bold text-yellow-800">Processing</th>
            <th class="pl-4 font-bold text-green-800">Aanvaard</th>
            <th class="pl-4 font-bold text-red-800">Geweigerd</th>
          </tr>
          <tr
            v-for="(source, index) in TeamleaderDealsSplitByMonthPerSource[
              monthIndex
            ].deals"
            :key="index"
            class="text-sm"
            v-show="source.deals.length != 0"
          >
            <td class="font-medium text-gray-700">{{ source.name }}</td>
            <td class="pl-4 font-bold">
              <span class="text-blue-800">{{ source.deals.length }}</span>
              <span class="ml-3 text-blue-600"
                >&euro;
                {{ source.totalDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-yellow-800">{{
                source.processingDeals.length
              }}</span>
              <span class="ml-3 text-yellow-800">{{
                ((source.processingDeals.length / source.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-yellow-600"
                >&euro;
                {{
                  source.totalProcessingDealsValue | rounded(0) | localized
                }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-green-800">{{ source.wonDeals.length }}</span>
              <span class="ml-3 text-green-800">{{
                ((source.wonDeals.length / source.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-green-600"
                >&euro;
                {{ source.totalWonDealsValue | rounded(0) | localized }}</span
              >
            </td>
            <td class="pl-4 font-bold">
              <span class="text-red-800">{{ source.lostDeals.length }}</span>
              <span class="ml-3 text-red-800">{{
                ((source.lostDeals.length / source.deals.length) * 100)
                  | percentage
              }}</span>
              <span class="ml-3 text-red-600"
                >&euro;
                {{ source.totalLostDealsValue | rounded(0) | localized }}</span
              >
            </td>
          </tr>
        </template>
      </number-row>
    </div>
    <div v-if="activeTab === 1" class="p-4 mx-auto max-w-7xl">
      <div
        class="flex items-center px-3 py-2 text-sm text-gray-600 bg-white rounded shadow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="ml-2 font-bold"
          >De Google Analytics gegevens van mei, juni en juli 2021 komen uit
          GA4, waar de rest uit UA komt</span
        >
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart :data="visitorsChartData" :options="visitorsChartOptions" />
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart
          :data="aanvragenChartData"
          :options="aanvragenChartOptions"
        />
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart
          :data="splitConversionChartData"
          :options="splitConversionChartOptions"
        />
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart
          :data="splitOrderValueChartData"
          :options="splitOrderValueChartOptions"
        />
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart
          :data="splitOrderConversionChartData"
          :options="splitOrderConversionChartOptions"
        />
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart
          :data="aanvraagSourceChartData"
          :options="aanvraagSourceChartOptions"
        />
      </div>
      <div class="p-4 mt-4 bg-white rounded">
        <bar-chart
          :data="orderSourceChartData"
          :options="orderSourceChartOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

import NumberRow from "@/components/NumberRow.vue";
import NumberBox from "@/components/NumberBox.vue";

import BarChart from "@/components/BarChart";

export default {
  name: "home",
  components: {
    NumberRow,
    NumberBox,
    BarChart,
  },
  data() {
    return {
      visitorsChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Sitebezoekers Rivasono.nl",
        },
        legend: { display: false },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      },
      aanvragenChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Aanvragen",
        },
        legend: { display: false },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      },
      conversionChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Conversie",
        },
        legend: { display: false },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                label += ": ";
              }
              label += `${tooltipItem.yLabel.toFixed(2)}%`;
              return label;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 3,
                callback: function(value) {
                  return `${value.toFixed(2)}%`;
                },
              },
            },
          ],
        },
      },
      splitConversionChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Websiteconversie",
        },
        legend: { position: "bottom" },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                label += ": ";
              }
              label += `${tooltipItem.yLabel.toFixed(2)}%`;
              return label;
            },
          },
        },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                max: 3,
                callback: function(value) {
                  return `${value.toFixed(2)}%`;
                },
              },
            },
          ],
        },
      },
      splitOrderValueChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Totale orderwaarde van de aanvragen",
        },
        legend: { position: "bottom" },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                label += ": ";
              }
              label += `€ ${tooltipItem.yLabel.toFixed(2)}`;
              return label;
            },
          },
        },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: function(value) {
                  return `€ ${value.toFixed(2)}`;
                },
              },
            },
          ],
        },
      },
      splitOrderConversionChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Orderconversie",
        },
        legend: { position: "bottom" },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";
              if (label) {
                label += ": ";
              }
              label += `${tooltipItem.yLabel.toFixed(2)}%`;
              return label;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                callback: function(value) {
                  return `${value.toFixed(2)}%`;
                },
              },
            },
          ],
        },
      },
      aanvraagSourceChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Aanvraagbronnen",
        },
        legend: { position: "bottom" },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
      orderSourceChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Orderbronnen",
        },
        legend: { position: "bottom" },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },
  computed: {
    visitorsChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: [
          {
            label: "Sitebezoekers",
            backgroundColor: "#1c3f61",
            data: this.chartSiteVisitors,
          },
        ],
      };
    },
    aanvragenChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: [
          {
            label: "Aanvragen",
            backgroundColor: "#c94949",
            data: this.chartAanvragen,
          },
        ],
      };
    },
    conversionChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: [
          {
            label: "Conversie",
            backgroundColor: "#1c3f61",
            data: this.chartSiteConversion,
          },
        ],
      };
    },
    splitConversionChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: [
          {
            label: "Conversie zakelijk",
            backgroundColor: "#1c3f61",
            data: this.chartSplitSiteConversion.business,
          },
          {
            label: "Conversie particulier",
            backgroundColor: "#c94949",
            data: this.chartSplitSiteConversion.living,
          },
        ],
      };
    },
    splitOrderValueChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: [
          {
            label: "Orderwaarde zakelijk",
            backgroundColor: "#1c3f61",
            data: this.chartSplitOrderValue.business,
          },
          {
            label: "Orderwaarde particulier",
            backgroundColor: "#c94949",
            data: this.chartSplitOrderValue.living,
          },
        ],
      };
    },
    splitOrderConversionChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: [
          {
            label: "Orderconversie zakelijk",
            backgroundColor: "#1c3f61",
            data: this.chartSplitOrderConversion.business,
          },
          {
            label: "Orderconversie particulier",
            backgroundColor: "#c94949",
            data: this.chartSplitOrderConversion.living,
          },
        ],
      };
    },
    aanvraagSourceChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: this.aanvraagSourceDatasets,
      };
    },
    orderSourceChartData() {
      return {
        labels: this.chartMonthLabels,
        datasets: this.orderSourceDatasets,
      };
    },
    chartMonthLabels() {
      const array = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        array.unshift(`${month.month.substring(0, 3)}, ${month.year}`);
      });
      return array;
    },
    chartSiteVisitors() {
      const array = [];
      this.GoogleAnalyticsAndGa4DataMerged.forEach(month => {
        array.unshift(Number(month));
      });
      return array;
    },
    chartAanvragen() {
      const array = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        array.unshift(month.deals.length);
      });
      return array;
    },
    chartSiteConversion() {
      const array = [];
      this.chartAanvragen.forEach((month, index) => {
        array.push((month / this.chartSiteVisitors[index]) * 100);
      });
      return array;
    },
    chartSplitSiteConversion() {
      return this.calculateSplitSiteConversion();
    },
    chartSplitOrderValue() {
      return this.calculateSplitOrderValue();
    },
    chartSplitOrderConversion() {
      return this.calculateSplitOrderConversion();
    },
    aanvraagSourceDatasets() {
      return this.calculateAanvraagSourceDatasets();
    },
    orderSourceDatasets() {
      return this.calculateOrderSourceDatasets();
    },
    activeTab: get("activeTab"),
    TeamleaderUsers() {
      return this.$store.getters.TeamleaderUsers;
    },
    TeamleaderUsersLoaded() {
      return this.$store.getters.TeamleaderUsersLoaded;
    },
    TeamleaderDealSources() {
      return this.$store.getters.TeamleaderDealSources;
    },
    TeamleaderDealSourcesLoaded() {
      return this.$store.getters.TeamleaderDealSourcesLoaded;
    },
    TeamleaderDealPhases() {
      return this.$store.getters.TeamleaderDealPhases;
    },
    TeamleaderDealPhasesLoaded() {
      return this.$store.getters.TeamleaderDealPhasesLoaded;
    },
    TeamleaderDeals() {
      return this.$store.getters.TeamleaderDeals;
    },
    TeamleaderDealsLoaded() {
      return this.$store.getters.TeamleaderDealsLoaded;
    },
    TeamleaderEarliestDealDate() {
      const dealCreationDates = [];
      this.TeamleaderDeals.forEach(deal => {
        const dealCreationDate = new Date(deal.created_at);
        dealCreationDates.push(dealCreationDate);
      });
      const earliestDate = Math.min(...dealCreationDates);
      return new Date(earliestDate).toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    TeamleaderLatestDealDate() {
      const dealCreationDates = [];
      this.TeamleaderDeals.forEach(deal => {
        const dealCreationDate = new Date(deal.created_at);
        dealCreationDates.push(dealCreationDate);
      });
      const latestDate = Math.max(...dealCreationDates);
      return new Date(latestDate).toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    TeamleaderDealsTotalValues() {
      let totalValuesObject = {
        totalDealsValue: 0,
        totalProcessingDealsValue: 0,
        totalWonDealsValue: 0,
        totalLostDealsValue: 0,
      };
      this.TeamleaderDeals.forEach(deal => {
        totalValuesObject.totalDealsValue += deal.estimated_value.amount;
        if (deal.status == "won") {
          totalValuesObject.totalWonDealsValue += deal.estimated_value.amount;
        } else if (deal.status == "lost") {
          totalValuesObject.totalLostDealsValue += deal.estimated_value.amount;
        } else {
          totalValuesObject.totalProcessingDealsValue +=
            deal.estimated_value.amount;
        }
      });
      return totalValuesObject;
    },
    TeamleaderDealsPerLeadType() {
      const companyDeals = this.TeamleaderDeals.filter(deal => {
        return deal.lead.customer.type == "company";
      });
      const contactDeals = this.TeamleaderDeals.filter(deal => {
        return deal.lead.customer.type == "contact";
      });
      const leadsArray = [
        {
          name: "Zakelijk",
          totalDealsValue: 0,
          totalWonDealsValue: 0,
          totalLostDealsValue: 0,
          totalProcessingDealsValue: 0,
          deals: companyDeals,
          wonDeals: this.getWonDeals(companyDeals),
          lostDeals: this.getLostDeals(companyDeals),
          processingDeals: this.getProcessingDeals(companyDeals),
        },
        {
          name: "Particulier",
          totalDealsValue: 0,
          totalWonDealsValue: 0,
          totalLostDealsValue: 0,
          totalProcessingDealsValue: 0,
          deals: contactDeals,
          wonDeals: this.getWonDeals(contactDeals),
          lostDeals: this.getLostDeals(contactDeals),
          processingDeals: this.getProcessingDeals(contactDeals),
        },
      ];
      leadsArray.forEach(lead => {
        lead.deals.forEach(deal => {
          const amount = deal.estimated_value.amount;
          lead.totalDealsValue += amount;
          if (deal.status == "won") {
            lead.totalWonDealsValue += amount;
          } else if (deal.status == "lost") {
            lead.totalLostDealsValue += amount;
          } else {
            lead.totalProcessingDealsValue += amount;
          }
        });
      });
      return leadsArray;
    },
    TeamleaderDealsPerSource() {
      const sourcesArray = [];
      this.TeamleaderDealSources.forEach(source => {
        let sourceObject = {
          name: source.name,
          totalDealsValue: 0,
          totalWonDealsValue: 0,
          totalLostDealsValue: 0,
          totalProcessingDealsValue: 0,
          deals: this.TeamleaderDeals.filter(deal => {
            if (deal.source) {
              return deal.source.id == source.id;
            } else {
              return false;
            }
          }),
          wonDeals: this.TeamleaderDeals.filter(deal => {
            if (deal.source) {
              return deal.source.id == source.id && deal.status == "won";
            }
          }),
          lostDeals: this.TeamleaderDeals.filter(deal => {
            if (deal.source) {
              return deal.source.id == source.id && deal.status == "lost";
            }
          }),
          processingDeals: this.TeamleaderDeals.filter(deal => {
            if (deal.source) {
              return (
                deal.source.id == source.id &&
                (deal.status == "new" || deal.status == "open")
              );
            }
          }),
        };
        sourceObject.deals.forEach(deal => {
          const amount = deal.estimated_value.amount;
          sourceObject.totalDealsValue += amount;
          if (deal.status == "won") {
            sourceObject.totalWonDealsValue += amount;
          } else if (deal.status == "lost") {
            sourceObject.totalLostDealsValue += amount;
          } else {
            sourceObject.totalProcessingDealsValue += amount;
          }
        });
        sourcesArray.push(sourceObject);
        sourcesArray.sort((a, b) => {
          if (a.totalDealsValue < b.totalDealsValue) {
            return 1;
          } else if (a.totalDealsValue > b.totalDealsValue) {
            return -1;
          } else {
            return 0;
          }
        });
      });
      return sourcesArray;
    },
    TeamleaderDealsPerUser() {
      const usersArray = [];
      this.TeamleaderUsers.forEach(user => {
        let userObject = {
          name: `${user.first_name} ${user.last_name}`,
          totalDealsValue: 0,
          totalWonDealsValue: 0,
          totalLostDealsValue: 0,
          totalProcessingDealsValue: 0,
          deals: this.TeamleaderDeals.filter(deal => {
            return user.id == deal.responsible_user?.id;
          }),
          wonDeals: this.TeamleaderDeals.filter(deal => {
            return user.id == deal.responsible_user?.id && deal.status == "won";
          }),
          lostDeals: this.TeamleaderDeals.filter(deal => {
            return (
              user.id == deal.responsible_user?.id && deal.status == "lost"
            );
          }),
          processingDeals: this.TeamleaderDeals.filter(deal => {
            return (
              user.id == deal.responsible_user?.id &&
              (deal.status == "open" || deal.status == "new")
            );
          }),
        };
        userObject.deals.forEach(deal => {
          const amount = deal.estimated_value.amount;
          userObject.totalDealsValue += amount;
          if (deal.status == "won") {
            userObject.totalWonDealsValue += amount;
          } else if (deal.status == "lost") {
            userObject.totalLostDealsValue += amount;
          } else {
            userObject.totalProcessingDealsValue += amount;
          }
        });
        usersArray.push(userObject);
        usersArray.sort((a, b) => {
          a = a.totalWonDealsValue;
          if (isNaN(a)) a = 0;
          b = b.totalWonDealsValue;
          if (isNaN(b)) b = 0;
          return b - a;
        });
      });
      return usersArray;
    },
    TeamleaderDealsWithEstimatedValue() {
      const dealsWithEstimatedValue = [];
      this.TeamleaderDeals.forEach(deal => {
        if (deal.estimated_value.amount > 0) {
          dealsWithEstimatedValue.push(deal);
        }
      });
      return dealsWithEstimatedValue;
    },
    TeamleaderDealsSplitByMonth() {
      return this.getDealsPerMonth();
    },
    TeamleaderDealsSplitByMonthTotalValues() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        let totalValuesObject = {
          totalDealsValue: 0,
          totalProcessingDealsValue: 0,
          totalWonDealsValue: 0,
          totalLostDealsValue: 0,
        };
        month.deals.forEach(deal => {
          totalValuesObject.totalDealsValue += deal.estimated_value.amount;
          if (deal.status == "won") {
            totalValuesObject.totalWonDealsValue += deal.estimated_value.amount;
          } else if (deal.status == "lost") {
            totalValuesObject.totalLostDealsValue +=
              deal.estimated_value.amount;
          } else {
            totalValuesObject.totalProcessingDealsValue +=
              deal.estimated_value.amount;
          }
        });
        monthsArray.push({
          totalValues: totalValuesObject,
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthInProcess() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        monthsArray.push({
          ...month,
          deals: month.deals.filter(deal => {
            return deal.status == "new" || deal.status == "open";
          }),
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthWon() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        monthsArray.push({
          ...month,
          deals: month.deals.filter(deal => {
            return deal.status == "won";
          }),
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthLost() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        monthsArray.push({
          ...month,
          deals: month.deals.filter(deal => {
            return deal.status == "lost";
          }),
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthPerLeadType() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        const companyDeals = month.deals.filter(deal => {
          return deal.lead.customer.type == "company";
        });
        const contactDeals = month.deals.filter(deal => {
          return deal.lead.customer.type == "contact";
        });
        const leadsArray = [
          {
            name: "Zakelijk",
            totalDealsValue: 0,
            totalWonDealsValue: 0,
            totalLostDealsValue: 0,
            totalProcessingDealsValue: 0,
            deals: companyDeals,
            wonDeals: this.getWonDeals(companyDeals),
            lostDeals: this.getLostDeals(companyDeals),
            processingDeals: this.getProcessingDeals(companyDeals),
          },
          {
            name: "Particulier",
            totalDealsValue: 0,
            totalWonDealsValue: 0,
            totalLostDealsValue: 0,
            totalProcessingDealsValue: 0,
            deals: contactDeals,
            wonDeals: this.getWonDeals(contactDeals),
            lostDeals: this.getLostDeals(contactDeals),
            processingDeals: this.getProcessingDeals(contactDeals),
          },
        ];
        leadsArray.forEach(lead => {
          lead.deals.forEach(deal => {
            const amount = deal.estimated_value.amount;
            lead.totalDealsValue += amount;
            if (deal.status == "won") {
              lead.totalWonDealsValue += amount;
            } else if (deal.status == "lost") {
              lead.totalLostDealsValue += amount;
            } else {
              lead.totalProcessingDealsValue += amount;
            }
          });
        });
        monthsArray.push({
          ...month,
          deals: leadsArray,
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthPerSource() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        const sourcesArray = [];
        this.TeamleaderDealSources.forEach(source => {
          let sourceObject = {
            name: source.name,
            totalDealsValue: 0,
            totalWonDealsValue: 0,
            totalLostDealsValue: 0,
            totalProcessingDealsValue: 0,
            deals: month.deals.filter(deal => {
              if (deal.source) {
                return deal.source.id == source.id;
              } else {
                return false;
              }
            }),
            wonDeals: month.deals.filter(deal => {
              if (deal.source) {
                return deal.source.id == source.id && deal.status == "won";
              }
            }),
            lostDeals: month.deals.filter(deal => {
              if (deal.source) {
                return deal.source.id == source.id && deal.status == "lost";
              }
            }),
            processingDeals: month.deals.filter(deal => {
              if (deal.source) {
                return (
                  deal.source.id == source.id &&
                  (deal.status == "new" || deal.status == "open")
                );
              }
            }),
          };
          sourceObject.deals.forEach(deal => {
            const amount = deal.estimated_value.amount;
            sourceObject.totalDealsValue += amount;
            if (deal.status == "won") {
              sourceObject.totalWonDealsValue += amount;
            } else if (deal.status == "lost") {
              sourceObject.totalLostDealsValue += amount;
            } else {
              sourceObject.totalProcessingDealsValue += amount;
            }
          });
          sourcesArray.push(sourceObject);
          sourcesArray.sort((a, b) => {
            if (a.totalDealsValue < b.totalDealsValue) {
              return 1;
            } else if (a.totalDealsValue > b.totalDealsValue) {
              return -1;
            } else {
              return 0;
            }
          });
        });
        monthsArray.push({
          ...month,
          deals: sourcesArray,
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthPerUser() {
      const monthsArray = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        const usersArray = [];
        this.TeamleaderUsers.forEach(user => {
          const baseUser = {
            name: `${user.first_name} ${user.last_name}`,
            totalDealsValue: 0,
            totalWonDealsValue: 0,
            totalLostDealsValue: 0,
            totalProcessingDealsValue: 0,
          };
          let userObject = {
            ...baseUser,
            deals: month.deals.filter(deal => {
              return user.id == deal.responsible_user?.id;
            }),
            wonDeals: month.deals.filter(deal => {
              return (
                user.id == deal.responsible_user?.id && deal.status == "won"
              );
            }),
            lostDeals: month.deals.filter(deal => {
              return (
                user.id == deal.responsible_user?.id && deal.status == "lost"
              );
            }),
            processingDeals: month.deals.filter(deal => {
              return (
                user.id == deal.responsible_user?.id &&
                (deal.status == "open" || deal.status == "new")
              );
            }),
          };
          userObject.deals.forEach(deal => {
            const amount = deal.estimated_value.amount;
            userObject.totalDealsValue += amount;
            if (deal.status == "won") {
              userObject.totalWonDealsValue += amount;
            } else if (deal.status == "lost") {
              userObject.totalLostDealsValue += amount;
            } else {
              userObject.totalProcessingDealsValue += amount;
            }
          });
          usersArray.push(userObject);
          usersArray.sort((a, b) => {
            a = a.totalWonDealsValue;
            if (isNaN(a)) a = 0;
            b = b.totalWonDealsValue;
            if (isNaN(b)) b = 0;
            return b - a;
          });
        });
        monthsArray.push({
          ...month,
          deals: usersArray,
        });
      });
      return monthsArray;
    },
    TeamleaderDealsSplitByMonthAndPhase() {
      return this.getSplitByMonthDealsPerPhase();
    },
    TeamleaderDealsSplitByMonthWithEstimatedValue() {
      return this.getSplitByMonthDealsWithEstimatedValue();
    },
    TeamleaderNewDeals() {
      return this.$store.getters.TeamleaderNewDeals;
    },
    TeamleaderWonDeals() {
      return this.$store.getters.TeamleaderWonDeals;
    },
    TeamleaderLostDeals() {
      return this.$store.getters.TeamleaderLostDeals;
    },
    TeamleaderAuthenticated() {
      return this.$store.getters.TeamleaderAuthenticated;
    },
    GoogleAnalyticsData() {
      return this.$store.getters.GoogleAnalyticsData;
    },
    GoogleAnalyticsDataPerMonth() {
      const rows = this.GoogleAnalyticsData.reports[0].data.rows;
      const dataArray = [];
      rows.forEach(row => {
        dataArray.unshift(row.metrics[0].values[0]);
      });
      return dataArray;
    },
    GoogleAnalyticsDataTotal() {
      let totalValue = 0;
      this.GoogleAnalyticsDataPerMonth.forEach(value => {
        totalValue += Number(value);
      });
      return totalValue;
    },
    GoogleAnalyticsLoaded() {
      return this.$store.getters.GoogleAnalyticsLoaded;
    },
    GA4Data() {
      return this.$store.getters.GA4Data;
    },
    GA4DataPerMonth() {
      const rows = this.GA4Data.rows;
      const dataArray = [];
      rows.forEach(row => {
        dataArray.push(row.metricValues[0].value);
      });
      return dataArray;
    },
    GA4DataLoaded() {
      return this.$store.getters.GA4DataLoaded;
    },
    GoogleAnalyticsAndGa4DataMerged() {
      const GoogleAnalyticsMergedData = [...this.GoogleAnalyticsDataPerMonth];
      /*
      this.GA4DataPerMonth.forEach(month => {
        GoogleAnalyticsMergedData.unshift(month);
      });
      */
      GoogleAnalyticsMergedData[
        GoogleAnalyticsMergedData.length - 1 - 19
      ] = this.GA4DataPerMonth[0];
      GoogleAnalyticsMergedData[
        GoogleAnalyticsMergedData.length - 1 - 20
      ] = this.GA4DataPerMonth[1];
      GoogleAnalyticsMergedData[
        GoogleAnalyticsMergedData.length - 1 - 21
      ] = this.GA4DataPerMonth[2];
      return GoogleAnalyticsMergedData;
    },
    GoogleAnalyticsAndGa4DataMergedTotal() {
      let totalValue = 0;
      this.GoogleAnalyticsAndGa4DataMerged.forEach(value => {
        totalValue += Number(value);
      });
      return totalValue;
    },
  },
  methods: {
    calculateSplitSiteConversion() {
      const array = [];
      const array2 = [];
      const dealArray = this.TeamleaderDealsSplitByMonth.slice().reverse();
      dealArray.forEach((month, index) => {
        const amountOfBusinessDeals = month.deals.filter(deal => {
          return deal.lead.customer.type === "company";
        }).length;
        const amountOfLivingDeals = month.deals.filter(deal => {
          return deal.lead.customer.type === "contact";
        }).length;
        array.push(
          (amountOfBusinessDeals / this.chartSiteVisitors[index]) * 100
        );
        array2.push(
          (amountOfLivingDeals / this.chartSiteVisitors[index]) * 100
        );
      });
      return { business: array, living: array2 };
    },
    calculateSplitOrderValue() {
      const array = [];
      const array2 = [];
      const dealArray = this.TeamleaderDealsSplitByMonth.slice().reverse();
      dealArray.forEach((month, index) => {
        array.push(0);
        array2.push(0);
        month.deals.forEach(deal => {
          if (deal.lead.customer.type === "company") {
            array[index] += deal.weighted_value.amount;
          } else if (deal.lead.customer.type === "contact") {
            array2[index] += deal.weighted_value.amount;
          }
        });
      });
      return { business: array, living: array2 };
    },
    calculateSplitOrderConversion() {
      const array = [];
      const array2 = [];
      const dealArray = this.TeamleaderDealsSplitByMonth.slice().reverse();
      dealArray.forEach(month => {
        const amountOfBusinessDeals = month.deals.filter(deal => {
          return deal.lead.customer.type === "company";
        }).length;
        const amountOfWonBusinessDeals = month.deals.filter(deal => {
          return deal.lead.customer.type === "company" && deal.status === "won";
        }).length;
        const amountOfLivingDeals = month.deals.filter(deal => {
          return deal.lead.customer.type === "contact";
        }).length;
        const amountOfWonLivingDeals = month.deals.filter(deal => {
          return deal.lead.customer.type === "contact" && deal.status === "won";
        }).length;
        array.push((amountOfWonBusinessDeals / amountOfBusinessDeals) * 100);
        array2.push((amountOfWonLivingDeals / amountOfLivingDeals) * 100);
      });
      return { business: array, living: array2 };
    },
    calculateAanvraagSourceDatasets() {
      const datasetArray = [];
      const dealArray = this.TeamleaderDealsSplitByMonth.slice().reverse();
      this.TeamleaderDealSources.forEach(source => {
        const array = [];
        dealArray.forEach((month, index) => {
          const amountOfDealsWithCurrentSource = month.deals.filter(deal => {
            if (deal.source) {
              return deal.source.id === source.id;
            }
          }).length;
          array[index] = amountOfDealsWithCurrentSource;
        });
        const arrayHasNoDeals = array.every(value => {
          return value === 0;
        });
        if (arrayHasNoDeals === false) {
          datasetArray.push({
            label: source.name,
            backgroundColor:
              "#" + Math.floor(Math.random() * 16777215).toString(16),
            data: array,
          });
        }
      });
      return datasetArray;
    },
    calculateOrderSourceDatasets() {
      const datasetArray = [];
      const dealArray = this.TeamleaderDealsSplitByMonthWon.slice().reverse();
      this.TeamleaderDealSources.forEach(source => {
        const array = [];
        dealArray.forEach((month, index) => {
          const amountOfDealsWithCurrentSource = month.deals.filter(deal => {
            if (deal.source) {
              return deal.source.id === source.id;
            }
          }).length;
          array[index] = amountOfDealsWithCurrentSource;
        });
        const arrayHasNoDeals = array.every(value => {
          return value === 0;
        });
        if (arrayHasNoDeals === false) {
          datasetArray.push({
            label: source.name,
            backgroundColor:
              "#" + Math.floor(Math.random() * 16777215).toString(16),
            data: array,
          });
        }
      });
      return datasetArray;
    },
    getWonDeals(deals) {
      return deals.filter(deal => {
        return deal.status == "won";
      });
    },
    getLostDeals(deals) {
      return deals.filter(deal => {
        return deal.status == "lost";
      });
    },
    getProcessingDeals(deals) {
      return deals.filter(deal => {
        return deal.status == "new" || deal.status == "open";
      });
    },
    getDealsFromCertainMonth(month, year) {
      return this.TeamleaderDeals.filter(deal => {
        const dealDate = deal.created_at;
        const dealYear = dealDate.split("-")[0];
        const dealMonth = dealDate.split("-")[1];

        return dealMonth == month && dealYear == year;
      });
    },
    getDealsPerMonth() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      const monthNames = [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ];

      const dealsPerMonth = [];
      let allDealsSplit = false;

      let monthToGet = currentMonth;
      let yearToGet = currentYear;

      let i = 0;

      while (allDealsSplit == false) {
        if (monthToGet == 0) {
          monthToGet = 12;
          yearToGet -= 1;
        }

        const dealsFromCertainMonth = this.getDealsFromCertainMonth(
          monthToGet,
          yearToGet
        );

        if (dealsFromCertainMonth.length == 0 && i > 0) {
          allDealsSplit = true;
        } else {
          dealsPerMonth.push({
            month: monthNames[monthToGet - 1],
            year: yearToGet,
            deals: dealsFromCertainMonth,
          });
          monthToGet--;
        }
        i++;
      }

      return dealsPerMonth;
    },
    getSplitByMonthDealsPerPhase() {
      const dealsSplitByMonthAndPhase = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        const dealsPerPhase = [];
        this.TeamleaderDealPhases.forEach(phase => {
          dealsPerPhase.push({
            phase: phase.id,
            name: phase.name,
            deals: month.deals.filter(deal => {
              return deal.current_phase.id == phase.id;
            }),
          });
        });
        dealsSplitByMonthAndPhase.push({
          month: month.month,
          dealTotal: month.deals.length,
          dealsPerPhase: dealsPerPhase,
        });
      });

      return dealsSplitByMonthAndPhase;
    },
    getSplitByMonthDealsWithEstimatedValue() {
      const dealsSplitByMonthWithEstimatedValue = [];
      this.TeamleaderDealsSplitByMonth.forEach(month => {
        const dealsWithEstimatedValue = month.deals.filter(deal => {
          return deal.estimated_value.amount > 0;
        });
        dealsSplitByMonthWithEstimatedValue.push(dealsWithEstimatedValue);
      });
      return dealsSplitByMonthWithEstimatedValue;
    },
    averageDealTime(deals) {
      let averageTime = null;
      deals.forEach(deal => {
        const time = new Date(deal.closed_at) - new Date(deal.created_at);
        averageTime += time;
      });
      averageTime = averageTime / deals.length;
      averageTime = averageTime / (1000 * 60 * 60 * 24);
      averageTime = averageTime.toFixed(0);
      return averageTime + " dagen";
    },
  },
  filters: {
    percentage(value, precise) {
      if (!value) return "0%";
      if (value.typeOf == String) value = value.parseFloat();
      if (precise == "precise") {
        return value.toFixed(1) + "%";
      } else {
        return value.toFixed(0) + "%";
      }
    },
    rounded(value, decimals) {
      if (!value) return "0";
      if (value.typeOf == String) value = value.parseFloat();
      return Number(value.toFixed(decimals));
    },
    localized(value) {
      if (!value) return "";
      if (value.typeOf == String) return value;
      return value.toLocaleString();
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center justify-center flex-1 pb-2 my-4 bg-white border-t-2 shadow",class:{
    'border-blue-500': !_vm.isGreen && !_vm.isRed && !_vm.isYellow,
    'border-green-500': _vm.isGreen,
    'border-red-500': _vm.isRed,
    'border-yellow-500': _vm.isYellow,
  }},[_c('div',[(_vm.data.title)?_c('div',{staticClass:"px-4 pt-2 text-xs font-bold tracking-wide text-center text-gray-700 uppercase truncate",class:{
        'text-green-800': _vm.isGreen,
        'text-red-800': _vm.isRed,
        'text-yellow-800': _vm.isYellow,
      }},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]):_vm._e(),_c('div',{staticClass:"flex items-center justify-center px-4 font-bold",class:{
        'text-blue-800': !_vm.isGreen && !_vm.isRed && !_vm.isYellow,
        'text-green-700': _vm.isGreen,
        'text-red-700': _vm.isRed,
        'text-yellow-700': _vm.isYellow,
      }},[(_vm.data.value)?_c('div',{staticClass:"mr-2 text-xl"},[_vm._v(_vm._s(_vm.data.value))]):_vm._e(),(_vm.data.percentage)?_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.data.percentage,"precise"))+" ")]):_vm._e(),(_vm.data.dealsWithEstimatedValue)?_c('div',{staticClass:"ml-2 text-xs font-bold",class:{
          'text-blue-600': !_vm.isGreen && !_vm.isRed && !_vm.isYellow,
          'text-green-600': _vm.isGreen,
          'text-red-600': _vm.isRed,
          'text-yellow-600': _vm.isYellow,
        }},[_vm._v(" "+_vm._s(_vm.data.dealsWithEstimatedValue)+" ")]):_vm._e(),(_vm.data.dealsWithEstimatedValuePercentage)?_c('div',{staticClass:"ml-2 text-xs font-bold",class:{
          'text-blue-600': !_vm.isGreen && !_vm.isRed && !_vm.isYellow,
          'text-green-600': _vm.isGreen,
          'text-red-600': _vm.isRed,
          'text-yellow-600': _vm.isYellow,
        }},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.data.dealsWithEstimatedValuePercentage,"precise"))+" ")]):_vm._e()]),_c('div',{staticClass:"flex items-center justify-center px-4"},[(_vm.data.price)?_c('div',{staticClass:"text-sm font-bold",class:{
          'text-blue-600': !_vm.isGreen && !_vm.isRed && !_vm.isYellow,
          'text-green-600': _vm.isGreen,
          'text-red-600': _vm.isRed,
          'text-yellow-600': _vm.isYellow,
        }},[_vm._v(" € "+_vm._s(_vm._f("localized")(_vm._f("rounded")(_vm.data.price,0)))+" ")]):_vm._e(),(_vm.data.pricePercentage)?_c('div',{staticClass:"ml-2 text-xs font-bold",class:{
          'text-blue-600': !_vm.isGreen && !_vm.isRed && !_vm.isYellow,
          'text-green-600': _vm.isGreen,
          'text-red-600': _vm.isRed,
          'text-yellow-600': _vm.isYellow,
        }},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.data.pricePercentage,"precise"))+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import Vuex from "vuex";
// import router from "../router";
import axios from "axios";

import { make } from "vuex-pathify";
import pathify from "./pathify";

Vue.use(Vuex);

const state = {
  activeTab: 0,
  TeamleaderAuthenticated: false,
  TeamleaderAuthData: null,
  TeamleaderAPICallsInLastMinute: 0,
  TeamleaderUsers: [],
  TeamleaderUsersLoaded: false,
  TeamleaderDealSources: [],
  TeamleaderDealSourcesLoaded: false,
  TeamleaderDealPhases: [],
  TeamleaderDealPhasesLoaded: false,
  TeamleaderDeals: [],
  TeamleaderDealsLoaded: false,
  GoogleAnalyticsData: null,
  GoogleAnalyticsLoaded: false,
  GA4Data: null,
  GA4DataLoaded: null,
};

const getters = {
  ...make.getters(state),
  TeamleaderAPICallsInLastMinute: state => {
    return state.TeamleaderAPICallsInLastMinute;
  },
  TeamleaderNewDeals: state => {
    return state.TeamleaderDeals.filter(deal => {
      return deal.status == "new";
    });
  },
  TeamleaderWonDeals: state => {
    return state.TeamleaderDeals.filter(deal => {
      return deal.status == "won";
    });
  },
  TeamleaderLostDeals: state => {
    return state.TeamleaderDeals.filter(deal => {
      return deal.status == "lost";
    });
  },
};

const mutations = {
  ...make.mutations(state),
  set_TeamleaderAuthenticated: (state, payload) => {
    state.TeamleaderAuthenticated = payload;
  },
  set_TeamleaderAuthData: state => {
    let hash = window.location.hash.substring(1);
    hash = hash.split("&");
    const hashObjects = [];
    hash.forEach(object => {
      const objectSplit = object.split("=");
      hashObjects.push(objectSplit);
    });
    state.TeamleaderAuthData = Object.assign(
      ...hashObjects.map(([k, v]) => ({ [k]: v }))
    );
  },
  set_TeamleaderAPICallsInLastMinute: (state, payload) => {
    state.TeamleaderAPICallsInLastMinute = payload;
  },
  increment_TeamleaderAPICallsInLastMinute: state => {
    state.TeamleaderAPICallsInLastMinute++;
  },
  set_TeamleaderUsers: (state, payload) => {
    state.TeamleaderUsers = payload;
  },
  set_TeamleaderUsersLoaded: (state, payload) => {
    state.TeamleaderUsersLoaded = payload;
  },
  set_TeamleaderDealSources: (state, payload) => {
    state.TeamleaderDealSources = payload;
  },
  set_TeamleaderDealSourcesLoaded: (state, payload) => {
    state.TeamleaderDealSourcesLoaded = payload;
  },
  set_TeamleaderDealPhases: (state, payload) => {
    state.TeamleaderDealPhases = payload;
  },
  set_TeamleaderDealPhasesLoaded: (state, payload) => {
    state.TeamleaderDealPhasesLoaded = payload;
  },
  set_TeamleaderDeals: (state, payload) => {
    state.TeamleaderDeals = payload;
  },
  clear_TeamleaderDeals: state => {
    state.TeamleaderDeals = [];
  },
  add_TeamleaderDeals: (state, payload) => {
    state.TeamleaderDeals = state.TeamleaderDeals.concat(payload);
  },
  set_TeamleaderDealsLoaded: (state, payload) => {
    state.TeamleaderDealsLoaded = payload;
  },
  set_GoogleAnalyticsData: (state, payload) => {
    state.GoogleAnalyticsData = payload;
  },
  set_GoogleAnalyticsLoaded: (state, payload) => {
    state.GoogleAnalyticsLoaded = payload;
  },
  set_GA4Data: (state, payload) => {
    state.GA4Data = payload;
  },
  set_GA4DataLoaded: (state, payload) => {
    state.GA4DataLoaded = payload;
  },
};

const actions = {
  checkIfTeamleaderAuthenticated: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (window.location.hash.includes("access_token=")) {
        commit("set_TeamleaderAuthenticated", true);
        commit("set_TeamleaderAuthData");
        resolve();
      } else {
        reject();
      }
    });
  },
  fetchTeamleaderUsers: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      commit("increment_TeamleaderAPICallsInLastMinute");
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + state.TeamleaderAuthData.access_token,
          "Content-Type": "application/json",
        },
        url: "https://api.teamleader.eu/users.list",
      })
        .then(res => {
          commit("set_TeamleaderUsers", res.data.data);
          commit("set_TeamleaderUsersLoaded", true);
          resolve();
        })
        .catch(error => {
          window.location = "/";
          reject(error);
        });
    });
  },
  fetchTeamleaderDealSources: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      commit("increment_TeamleaderAPICallsInLastMinute");
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + state.TeamleaderAuthData.access_token,
          "Content-Type": "application/json",
        },
        url: "https://api.teamleader.eu/dealSources.list",
      })
        .then(res => {
          commit("set_TeamleaderDealSources", res.data.data);
          commit("set_TeamleaderDealSourcesLoaded", true);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchTeamleaderDealPhases: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      commit("increment_TeamleaderAPICallsInLastMinute");
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + state.TeamleaderAuthData.access_token,
          "Content-Type": "application/json",
        },
        url: "https://api.teamleader.eu/dealPhases.list",
      })
        .then(res => {
          commit("set_TeamleaderDealPhases", res.data.data);
          commit("set_TeamleaderDealPhasesLoaded", true);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchTeamleaderDeals: (
    { state, commit, dispatch },
    dealPageToRequest = 1
  ) => {
    return new Promise((resolve, reject) => {
      commit("increment_TeamleaderAPICallsInLastMinute");
      axios({
        method: "post",
        headers: {
          Authorization: "Bearer " + state.TeamleaderAuthData.access_token,
          "Content-Type": "application/json",
        },
        url: "https://api.teamleader.eu/deals.list",
        data: {
          page: {
            size: 99,
            number: dealPageToRequest,
          },
        },
      })
        .then(res => {
          const fetchedDealList = res.data.data;

          // Filter out sept 2019
          res.data.data = res.data.data.filter(deal => {
            return !deal.created_at.includes("2019-09");
          });

          const webshopSource = state.TeamleaderDealSources.find(source => {
            return source.name == "Webshop";
          });

          const bolSource = state.TeamleaderDealSources.find(source => {
            return source.name == "Bol.com";
          });

          const adiLivingSource = state.TeamleaderDealSources.find(source => {
            return source.name == "Akoestiek Direct - Particulier";
          });

          const adiBusinessSource = state.TeamleaderDealSources.find(source => {
            return source.name == "Akoestiek Direct - Zakelijk";
          });

          res.data.data = res.data.data.filter(deal => {
            if (deal.source) {
              return (
                deal.source.id != webshopSource.id &&
                deal.source.id != bolSource.id &&
                deal.source.id != adiLivingSource.id &&
                deal.source.id != adiBusinessSource.id
              );
            } else {
              return true;
            }
          });

          commit("add_TeamleaderDeals", res.data.data);
          if (fetchedDealList.length == 99) {
            dispatch("fetchTeamleaderDeals", dealPageToRequest + 1);
          } else {
            commit("set_TeamleaderDealsLoaded", true);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchGoogleAnalyticsData: ({ commit }) => {
    window.gapi.client
      .request({
        path: "/v4/reports:batchGet",
        root: "https://analyticsreporting.googleapis.com/",
        method: "POST",
        body: {
          reportRequests: [
            {
              viewId: "96391916",
              dateRanges: [
                {
                  startDate: "2019-10-01",
                  endDate: "today",
                },
              ],
              metrics: [
                {
                  expression: "ga:users",
                },
              ],
              dimensions: [
                {
                  name: "ga:yearMonth",
                },
              ],
            },
          ],
        },
      })
      .then(response => {
        commit("set_GoogleAnalyticsData", response.result);
        commit("set_GoogleAnalyticsLoaded", true);
      });
  },
  fetchGA4Data: ({ commit }) => {
    window.gapi.client
      .request({
        path: "/v1beta/properties/271210220:runReport",
        root: "https://analyticsdata.googleapis.com/",
        method: "POST",
        body: {
          metrics: [
            {
              name: "activeUsers",
            },
          ],
          dimensions: [
            {
              name: "month",
            },
          ],
          dateRanges: [
            {
              startDate: "2021-05-01",
              endDate: "today",
            },
          ],
        },
      })
      .then(response => {
        commit("set_GA4Data", response.result);
        commit("set_GA4DataLoaded", true);
      });
  },
};

const store = {
  state,
  getters,
  mutations,
  actions,
  plugins: [pathify.plugin],
};

export default new Vuex.Store(store);
